
import api from '@/plugins/api'
import { mapState, mapMutations } from 'vuex'
import WarningWrapper from "~/components/login/WarningWrapper";
import Separator from "~/components/login/Separator";
import storage from "~/plugins/localStorage";
export default {
  components: {
    WarningWrapper,
    Separator
  },
  computed: {
    ...mapState(['baseUrl'])
  },
  data() {
    return {
      account: "",
      accountConf: {
        show: false,
        text: "该手机号未注册"
      },
      password: "",
      passwordConf: {
        show: false,
        text: "请输入正确的密码"
      },
      thirdPartyList: [
        {
          des: "微信",
          img: "//static.buwangchuxin.com/red_open_palm/image/logo-wechat.png",
          link: "wechatLogin"
        },
        {
          des: "QQ",
          img: "//static.buwangchuxin.com/red_open_palm/image/logo-qq.png",
          link: "qqLogin"
        },
        {
          des: "微博",
          img: "//static.buwangchuxin.com/red_open_palm/image/logo-weibo.png",
          link: "weiboLogin"
        }
      ]
    };
  },
  mounted() {},
  methods: {
    ...mapMutations("user", ["updeteUserInfo", "updateAccountInfo"]),
    onRegisterClick() {
      this.$emit("changeState", "register");
    },
    onForgetClick() {
      this.$emit("changeState", "forget");
    },
    onThirdClick(link) {
      if (!link) {
        this.$message("该功能暂未开通");
        return;
      }
      this.thirdLogin(link);
    },
    onLoginClick() {
      if ([this.account, this.password].some(v => !v)) {
        this.$message.error("请填写完整");
        return;
      }
      this.login();
    },
    login() {
      this.$axios.$post(api.webLogin, {
        mobile: this.account,
        password: this.password,
        mode: "password"
      })
        .then(({ user_info }) => {
          this.updeteUserInfo(user_info);
          this.getAccountInfo();
          let name = this.$route.name;
          if (name == "login") {
            let hostName = this.$route.query.redirect || '';
            if (hostName && hostName == location.hostname) {
              this.$router.go(-1);
            } else {
              this.$router.replace({ name: 'index' });
            }
          }
          this.$message.success("登录成功");
        })
        .catch(e => {
          this.$message.error(e);
        });
    },

    /** 获取账户信息 */
    getAccountInfo() {
      this.$axios.$post(api.getAccountInfo)
        .then(result => {
          if(result && result.info) {
            this.updateAccountInfo(result.info);

            let userInfo = storage.getItem('userInfo') || {};
            if(userInfo.nick_name == result.info.nick_name) return;

            userInfo.nick_name = result.info.nick_name
            this.updeteUserInfo(userInfo);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    thirdLogin(link) {
      let BackUrl = "";
      try{
        if (
          window.location.href.indexOf("?") != -1
        ) {
          BackUrl = `${window.location.href}&state=bind`;
        } else BackUrl = `${window.location.href}?state=bind`;
      } catch{
        BackUrl = `${window.location.href}?state=bind`;
      }
      window.location = `${this.baseUrl}/api/Thirdlogin/${link}?backurl=${encodeURIComponent(
        BackUrl
      )}`;
    }
  }
};
