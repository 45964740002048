
import { mapState, mapGetters, mapMutations } from 'vuex'
import api from '@/plugins/api'
import SideBar from '~/components/personal/SideBar'
import Dialog from '~/components/Dialog'
export default {
  name: 'NavBar',
  components: { SideBar, Dialog },
  data() {
    return{
      hostname: '',
      isSearch: false,
      whiteNav: false,
      isPersonal: false, // 个人中心列表
      isLoginOut: false, // 退出登录弹窗
      sideList: [
        { text: '账户设置', icon: '//static.buwangchuxin.com/red_open_palm/image/new/personal-set.png', 
        children: [
            { text: '账户信息', name: 'personal'},
            { text: '修改密码', name: 'personal/password'},
            { text: '退出登录', name: '' , type: 'logout'}
        ]},
        { text: '账户中心', icon: '//static.buwangchuxin.com/red_open_palm/image/new/personal-center.png', 
        children: [
            { text: '收藏夹', name: 'personal/collect'},
            { text: '下载记录', name: 'personal/download'},
            { text: '购买记录', name: 'personal/buy'}
        ]}
      ],
    }
  },
  computed: {
    ...mapState(['isNavWhite', 'navList']),
    ...mapState('user', ['accountInfo']),
    ...mapGetters('user', ['token', 'userInfo'])
  },
  watch:{
    isNavWhite(val) {
      this.whiteNav = val
    },

    '$route'(val) {
      if(this.$router.currentRoute.name == 'map') {
        this.isSearch = true;
      } else this.isSearch = false;
    }
  },
  mounted() {
    if(this.token && (!this.accountInfo || !this.accountInfo.mobile)) this.getAccountInfo();
    if (process.client) {
      this.hostname = location.hostname
    }

    if (this.$router.currentRoute.name == 'map') {
      this.isSearch = true;
    } else this.isSearch = false;
    window.addEventListener('scroll', this.onScroll);
    document.addEventListener("click", this.close);
  },
  methods:{
    ...mapMutations('user', ['updeteUserInfo', 'updateAccountInfo']),

    /** 获取账户信息 */
    async getAccountInfo() {
      let result = await this.$axios.$post(api.getAccountInfo);
      this.updateAccountInfo(result);
    },

    onScroll() {
      if(this.isNavWhite) return;
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      if(scrollTop > 650) this.whiteNav = true;
      else this.whiteNav = false;
    },
    close() {
      this.isPersonal = false;
    },
    sureLoginOut() {
      this.updeteUserInfo({});
      this.isLoginOut = false;
      try{
        if(this.$route.path.indexOf('personal') != -1) {
          this.$router.replace({ name: 'login', query: { redirect: this.hostname } })
        }
      }catch{
        this.$router.replace({ name: 'login', query: { redirect: this.hostname } })
      }
      
    }
  },
  destroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
}
