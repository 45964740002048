import { render, staticRenderFns } from "./Player.vue?vue&type=template&id=162078d9&scoped=true&"
import script from "./Player.vue?vue&type=script&lang=js&"
export * from "./Player.vue?vue&type=script&lang=js&"
import style0 from "./Player.vue?vue&type=style&index=0&id=162078d9&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "162078d9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Process: require('/www/wwwroot/red_open_palm_3.0/components/Process.vue').default})
