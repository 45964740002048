  import storage from '~/plugins/localStorage'
  export const state = () => ({
    token: '',
    userInfo: {}, // 用户信息（登录、注册获取）
    accountInfo: {}, // 账户信息（个人中心）
    title: '',
    sideList: [
      { text: '账户设置', icon: '//static.buwangchuxin.com/red_open_palm/image/new/personal-set.png', children: [
        { text: '账户信息', name: 'personal'},
        { text: '修改密码', name: 'personal/password'},
      ]},
      { text: '账户中心', icon: '//static.buwangchuxin.com/red_open_palm/image/new/personal-center.png', children: [
          // { text: '收藏夹', name: 'personal/collect'},
          { text: '下载记录', name: 'personal/download'},
          { text: '购买记录', name: 'personal/buy'}
      ]}
    ],
    downList: [], // 下载记录
    buyList: [], // 购买记录
    pageInfo: {
      counts: 0,
      page: 1,
      page_size: 8,
      page_total: 0
    }
  })
  
  export const getters = {
    userInfo: state => {
      return state.userInfo && state.userInfo.token ? state.userInfo : storage.getItem('userInfo') || {}
    },
    token: state => state.userInfo.token || storage.getItem('userInfo').token || '',
    userAvatar: state => state.userInfo.head_path || storage.getItem('userInfo').head_path || '',
    userName: state => state.userInfo.nick_name || storage.getItem('userInfo').nick_name || ''
  }

  export const mutations = {
    setTitle(state, data) {
      state.title = data
    },

    updeteUserInfo(state, data) {
      data.time = new Date().getTime();
      storage.setItem('userInfo', data);
      state.userInfo = data;
    },

    updateAvatar(state, url) {
      state.userInfo.head_path = url;
      storage.updateAvatar(url);
    },

    updateName(state, name) {
      state.userInfo.nick_name = name;
      storage.updateName(name);
    },

    updateAccountInfo(state, result) {
      if(!result || !result.info) return;

      state.accountInfo = result.info;
      let obj = {};
      obj = state.userInfo && state.userInfo.token ? state.userInfo : storage.getItem('userInfo') || {}
      if(obj.nick_name == result.info.nick_name) return;

      obj.nick_name = result.info.nick_name
      storage.setItem('userInfo', obj);
      state.userInfo = obj;
    },

    setDownList(state, result) {
      state.downList = result.list || [];
      state.pageInfo = result.page_info || {}
    },

    setBuyList(state, result) {
      state.buyList = result.list || [];
      state.pageInfo = result.page_info || {}
    }

  }