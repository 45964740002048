
import { mapState, mapMutations } from 'vuex'
import axios from 'axios'
import api from '@/plugins/api'
import NavBar from '~/components/NavBar'
import Footer from '~/components/Footer'
import Player from "~/components/map/Player";
import VrMask from "~/components/vrMask";
import LoginDialog from '~/components/login/Dialog'
export default {
  components: { NavBar, Footer, Player, VrMask, LoginDialog },
  computed: {
    ...mapState({
      currentPage: state => state.currentPage,
      isNav: state => state.isNav,
      isFooter: state => state.isFooter,
      isLogin: state => state.isLogin
    })
  },
  data() {
    return {
      loadingHidde: true
    }
  },
  mounted() {
    console.log('currentRoute=',this.$router.currentRoute)
    // 设置路由
    this.setRoute(); 
    this.getUserInfo();

    // 监听窗口大小的改变
    window.addEventListener('load', () => this.loadingHidde = true);
    window.addEventListener('resize', () => this.$store.commit('getWindowSize'));
    this.$store.commit('getWindowSize');
  },
  methods: {
    ...mapMutations("user", ["updeteUserInfo", "updateAccountInfo"]),
    getUserInfo() {
      if(this.$route.query && this.$route.query.bindToken) {
        this.$router.push({ name: 'login', query: { redirect: location.hostname, state: 'bind', bindToken: this.$route.query.bindToken } })
        return;
      }
      if(!this.$route.query || (!this.$route.query.token && !this.$route.query.bindToken)) return null;
      let info = {
        nick_name: this.$route.query.nick_name,
        head_path: this.$route.query.head_path,
        token: this.$route.query.token || this.$route.query.bindToken
      }
      this.updeteUserInfo(info);
    },
    setRoute() {
      this.$store.commit('setCurrentPage', this.$router.currentRoute);

      // 监听路由变化
      this.$router.beforeEach((to, from, next) => {
        this.$nuxt.$loading.start();
        next();
      })
      this.$router.afterEach((to, from) => {
        this.$store.commit('setCurrentPage', to);
        this.$nuxt.$loading.finish();
      })
    },

    /** 获取账户信息 */
    getAccountInfo() {
      this.$axios.$post(api.getAccountInfo)
        .then(result => {
          if(result && result.info) {
            this.updateAccountInfo(result.info);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
  }
}
