import storage from './localStorage'
export default function ({store, redirect, req, router, app: { $axios }})  {
    // request拦截器
    $axios.interceptors.request.use(
        config => {
            const token = store.getters['user/token'] || storage.getItem('userInfo').token || ''
            if(!config.data) config.data = {}
            config.data['token'] = token

            if (process.client && config.url.indexOf('/api/Upload/image') != -1) {
                const formData = new FormData()
                for (let key in config.data) formData.append(key, config.data[key])
                config.data = formData
            }

            return config
        },
        err => {
            return Promise.reject(err || '啊哦~ 出错啦, 请稍后再试！')
    })

     // response拦截器，数据返回后，可以先在这里进行一个简单的判断
     $axios.interceptors.response.use(
        response => {
            console.log('---api--->',response.config.url,' ----data--->', response.data)
            if(response.status != 200) return Promise.reject(response.statusText || '请求失败，请稍后再试~');
            if(Object.prototype.toString.call(response.data)=='[object String]') {
                response.data = response.data.trim();
                try {
                    response.data = JSON.parse(response.data);
                } catch(e) {
                    console.log('catch error: ', e)
                }
            }
            if(response.data.error_code == 0) return response.data;
            if(response.data.error_code == 1001 || response.data.error_code == 1002) {
                if (process.client) return redirect('/login?redirect=' + location.hostname)
                return response
            }
            if(!response.data.error_code) return response;
            return Promise.reject(response.data.error_msg || '啊哦~网络飞到外太空啦，请稍后再试！')
        },
        error => {
            console.log('error==',error)
            return Promise.reject(error.response || '啊哦~出错啦，请稍后再试！')   // 返回接口返回的错误信息
    })
}