
export default {
  props: {
    percent: {
      type: Number,
      default: 0
    },
    proStyle: { type: Object, default: () => {
      return {
        height: 10,
        lineColor: '#F27C0E',
        roundColor: '#fff',
        roundSize: 24,
        bgColor: '#ccc',
      }
    }}
  },
  methods: {
    eventStart(e) {
      this.touch.initiated = true;
      this.touch.startX = e.pageX || e.touches[0].pageX;
      this.touch.left = this.$refs.progress.clientWidth;
    },
    eventMove(e) {
      if (!this.touch.initiated) {
        return;
      }
      const deltaX = (e.pageX || e.touches[0].pageX) - this.touch.startX;
      const offsetWidth = Math.min(
        this.$refs.progressBar.clientWidth,
        Math.max(0, this.touch.left + deltaX)
      );
      this.changeWidth(offsetWidth);
      this.$emit('move', this.getPercent());
    },
    eventEnd() {
      this.touch.initiated = false;
      this.changePercent();
    },
    onBarClick(e) {
      const rect = this.$refs.progressBar.getBoundingClientRect();
      const offsetWidth = e.pageX - rect.left;
      this.changeWidth(offsetWidth);
      this.changePercent();
    },
    setWidth(percent) {
      if (percent >= 0 && !this.touch.initiated) {
        const barWidth = this.$refs.progressBar.clientWidth;
        const offsetWidth = percent * barWidth;
        this.changeWidth(offsetWidth);
      }
    },
    changePercent() {
      this.$emit('change', this.getPercent());
    },
    changeWidth(offsetWidth) {
      this.$refs.progress.style.width = `${offsetWidth}px`;
      this.$refs.progressBtn.style.transform = `translate3d(${offsetWidth}px,-50%,0)`;
    },
    getPercent() {
      const barWidth = this.$refs.progressBar.clientWidth;
      return this.$refs.progress.clientWidth / barWidth;
    }
  },
  watch: {
    percent(newPercent) {
      this.$nextTick(() => {
        this.setWidth(newPercent);
      });
    }
  },
  created() {
    
  },
  mounted() {
    this.touch = {};
    window.addEventListener('mouseup', () => {
      if (this.touch.initiated) {
        this.touch.initiated = false;
        this.changePercent();
      }
    });
    this.setWidth(this.percent);
  }
};
