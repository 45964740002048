
import api from '@/plugins/api'
import WarningWrapper from "~/components/login/WarningWrapper";
import VModule from '~/components/login/VerifyCode';
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    WarningWrapper,
    VModule
  },
  data() {
    return {
      isShowPaint: false,
      randomCode: "",
      pCode: "",
      ruleForm: {
        account: "",
        vCode: "",
        password: "",
        checkPass: ""
      },
      accountConf: {
        show: false,
        text: "手机号格式错误"
      },
      pCodeConf: {
        show: false,
        text: "请输入正确的字符"
      },
      vCodeConf: {
        show: false,
        text: "请输入正确的验证码"
      },
      passwordConf: {
        show: false,
        text: "密码格式错误"
      },
      checkPassConf: {
        show: false,
        text: "两次密码不一致"
      },
      rules: {
        account: [{ validator: this.validateAccount, trigger: "blur" }],
        vCode: [{ validator: this.validateVCode, trigger: "blur" }],
        password: [{ validator: this.validatePass, trigger: "blur" }],
        checkPass: [{ validator: this.validatePass2, trigger: "blur" }]
      }
    };
  },
  computed: {
    ...mapState(['baseUrl']),
    codeImg() {
      return `${this.baseUrl}/api/Webmap/getCaptcha?captcha_id=${
        this.randomCode
      }`;
    }
  },
  methods: {
    ...mapMutations("user", ["updeteUserInfo"]),
    checkPhone(value) {
      return /^1[345789]\d{9}$/.test(value);
    },
    checkPassword(value) {
      return /^[a-zA-Z0-9]{6,16}$/.test(value);
    },
    refreshPaint() {
      this.pCodeConf.show = false;
      this.isShowPaint = false;
      this.randomCode = Math.random()
        .toString(36)
        .substring(2);
      this.$nextTick(() => {
        this.isShowPaint = true;
      });
    },
    onVCodeClick() {
      this.$refs.ruleForm.validateField("account");
      if (!this.pCode) {
        this.pCodeConf.show = true;
        this.$refs.vCode.ClearTimer();
        return;
      }
      if (this.checkPhone(this.ruleForm.account)) {
        this.getVCode();
      } else {
        this.$refs.vCode.ClearTimer();
      }
    },
    onLoginClick() {
      this.$emit("changeState", "login");
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.register();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    showError(config, status = true, text) {
      if (text) {
        config.text = text;
      }
      config.show = status;
    },
    validateAccount(rule, value, callback) {
      if (this.checkPhone(value)) {
        callback();
        this.showError(this.accountConf, false);
      } else {
        this.showError(this.accountConf);
        callback(new Error());
      }
    },
    validatePass(rule, value, callback) {
      if (this.checkPassword(value)) {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
        this.showError(this.passwordConf, false);
      } else {
        callback(new Error());
        this.showError(this.passwordConf);
      }
    },
    validatePass2(rule, value, callback) {
      if (value !== this.ruleForm.password) {
        callback(new Error());
        this.showError(this.checkPassConf);
      } else {
        callback();
        this.showError(this.checkPassConf, false);
      }
    },
    validateVCode(rule, value, callback) {
      if (value === "") {
        callback(new Error());
        this.showError(this.vCodeConf);
      } else {
        callback();
        this.showError(this.vCodeConf, false);
      }
    },
    getVCode() {
      this.$axios.$post(api.sendSms, {
        mobile: this.ruleForm.account,
        type: 1,
        verifycode: this.pCode,
        captcha_id: this.randomCode
      }).catch(e => {
        this.$message.error(e);
        this.$refs.vCode.ClearTimer();
      });
    },
    register() {
      this.$axios.$post(api.webRegister, {
        mobile: this.ruleForm.account,
        code: this.ruleForm.vCode,
        password: this.ruleForm.password,
        repassword: this.ruleForm.checkPass
      }).then(({ user_info }) => {
          this.updeteUserInfo(user_info);
          let name = this.$route.name;
          if (name == "login") this.$router.go(-1);
          this.$message.success("注册成功，自动登录");
        })
        .catch(err => {
          this.$message.error(err || "啊哦~网络在开小差，请稍后再试！");
        });
    }
  },
  watch: {
    pCode() {
      this.pCodeConf.show = false;
    }
  },
  mounted() {
    this.randomCode = Math.random()
      .toString(36)
      .substring(2);
    this.isShowPaint = true;
  }
};
