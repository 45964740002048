
import { mapState, mapMutations } from "vuex";
export default {
  name: 'personal-side',
  props: {
      type: { type: String, default: '' }
  },
  data() {
    return {
        current: '00',
        realList: []
    }
  },
  computed: {
      ...mapState('user', ['sideList'])
  },
  watch: {
    '$route.name' () {
      this.init()
    }
  },
  created() {},
  mounted() {
      if(this.type && this.type == 'nav') this.current = '0';
      this.init();
  },
  methods: {
      ...mapMutations('user', ['setTitle']),
      init() {
        let name = this.$route.name,
            len = this.sideList.length;
        if (len <= 0) return false

        for(let i = 0; i < len; i++) {
            if(!this.sideList[i].children || this.sideList[i].children.length == 0) continue;
            let childLen = this.sideList[i].children.length;
            for(let j = 0; j < childLen; j++) {
                if(this.sideList[i].children[j].name == name){ 
                    this.current = i + '' + j;
                    this.setTitle(this.sideList[i].children[j].text);
                }
            }
        }
      },
      
      change(val, index, i) {
          if(!val.name) {
              this.$emit('logout')
              return;
          }
          this.current = index+''+i;
          this.$router.push({name:val.name})
      }
  }
}
