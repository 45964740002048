import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _23ef0694 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _b2aaeeee = () => interopDefault(import('../pages/create/index.vue' /* webpackChunkName: "pages/create/index" */))
const _161db4dc = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _6f5b8345 = () => interopDefault(import('../pages/map/index.vue' /* webpackChunkName: "pages/map/index" */))
const _77483e74 = () => interopDefault(import('../pages/material/index.vue' /* webpackChunkName: "pages/material/index" */))
const _76eedf91 = () => interopDefault(import('../pages/pay/index.vue' /* webpackChunkName: "pages/pay/index" */))
const _b0e9656c = () => interopDefault(import('../pages/personal.vue' /* webpackChunkName: "pages/personal" */))
const _3177344d = () => interopDefault(import('../pages/personal/index.vue' /* webpackChunkName: "pages/personal/index" */))
const _7244acc1 = () => interopDefault(import('../pages/personal/buy.vue' /* webpackChunkName: "pages/personal/buy" */))
const _0dd293e5 = () => interopDefault(import('../pages/personal/collect.vue' /* webpackChunkName: "pages/personal/collect" */))
const _df7d1346 = () => interopDefault(import('../pages/personal/download.vue' /* webpackChunkName: "pages/personal/download" */))
const _6ff326f0 = () => interopDefault(import('../pages/personal/password.vue' /* webpackChunkName: "pages/personal/password" */))
const _3af61b1a = () => interopDefault(import('../pages/question/index.vue' /* webpackChunkName: "pages/question/index" */))
const _b1129b78 = () => interopDefault(import('../pages/type.vue' /* webpackChunkName: "pages/type" */))
const _2876c6cb = () => interopDefault(import('../pages/type/audio.vue' /* webpackChunkName: "pages/type/audio" */))
const _15449e04 = () => interopDefault(import('../pages/type/book.vue' /* webpackChunkName: "pages/type/book" */))
const _82c55e9a = () => interopDefault(import('../pages/type/picture.vue' /* webpackChunkName: "pages/type/picture" */))
const _6cb264f0 = () => interopDefault(import('../pages/type/video.vue' /* webpackChunkName: "pages/type/video" */))
const _2277b517 = () => interopDefault(import('../pages/type/vr.vue' /* webpackChunkName: "pages/type/vr" */))
const _1467f4ee = () => interopDefault(import('../pages/map/detail.vue' /* webpackChunkName: "pages/map/detail" */))
const _0a129f9f = () => interopDefault(import('../pages/material/detail.vue' /* webpackChunkName: "pages/material/detail" */))
const _19fd4882 = () => interopDefault(import('../pages/pay/success.vue' /* webpackChunkName: "pages/pay/success" */))
const _7d61146d = () => interopDefault(import('../pages/question/answer.vue' /* webpackChunkName: "pages/question/answer" */))
const _66c521e6 = () => interopDefault(import('../pages/question/list.vue' /* webpackChunkName: "pages/question/list" */))
const _0a2d9990 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _23ef0694,
    name: "about"
  }, {
    path: "/create",
    component: _b2aaeeee,
    name: "create"
  }, {
    path: "/login",
    component: _161db4dc,
    name: "login"
  }, {
    path: "/map",
    component: _6f5b8345,
    name: "map"
  }, {
    path: "/material",
    component: _77483e74,
    name: "material"
  }, {
    path: "/pay",
    component: _76eedf91,
    name: "pay"
  }, {
    path: "/personal",
    component: _b0e9656c,
    children: [{
      path: "",
      component: _3177344d,
      name: "personal"
    }, {
      path: "buy",
      component: _7244acc1,
      name: "personal/buy"
    }, {
      path: "collect",
      component: _0dd293e5,
      name: "personal/collect"
    }, {
      path: "download",
      component: _df7d1346,
      name: "personal/download"
    }, {
      path: "password",
      component: _6ff326f0,
      name: "personal/password"
    }]
  }, {
    path: "/question",
    component: _3af61b1a,
    name: "question"
  }, {
    path: "/type",
    component: _b1129b78,
    name: "type",
    children: [{
      path: "audio",
      component: _2876c6cb,
      name: "type/audio"
    }, {
      path: "book",
      component: _15449e04,
      name: "type/book"
    }, {
      path: "picture",
      component: _82c55e9a,
      name: "type/picture"
    }, {
      path: "video",
      component: _6cb264f0,
      name: "type/video"
    }, {
      path: "vr",
      component: _2277b517,
      name: "type/vr"
    }]
  }, {
    path: "/map/detail",
    component: _1467f4ee,
    name: "map/detail"
  }, {
    path: "/material/detail",
    component: _0a129f9f,
    name: "material/detail"
  }, {
    path: "/pay/success",
    component: _19fd4882,
    name: "pay/success"
  }, {
    path: "/question/answer",
    component: _7d61146d,
    name: "question/answer"
  }, {
    path: "/question/list",
    component: _66c521e6,
    name: "question/list"
  }, {
    path: "/",
    component: _0a2d9990,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
