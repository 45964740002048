export default function({route, req, store, redirect}) {
    // let isMobile = (ua) => {
    //     return !!ua.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    // }
    // let userAgent = req ? req.headers['user-agent'] : navigator.userAgent || '';

    // store.commit('judgeISMobile', isMobile(userAgent));

    // if(isMobile(userAgent) && route.fullPath.indexOf('/m') == -1) {
    //     let url = '';
    //     url = '/m' + route.fullPath;
    //     console.log('mobile url=',url)
    //     redirect(url);
    //     return;
    // }

    // if(!isMobile(userAgent) && route.fullPath.indexOf('/m') != -1) {
    //     let url = route.fullPath.substring(2);
    //     console.log('pc url=',url)
    //     redirect(url);
    //     return;
    // }
}