
import { mapState, mapGetters } from "vuex";
import ContentModuler from "./ContentModule";
export default {
  components: { ContentModuler },
  props: {
    show: { type: Boolean, default: false }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("user", ["token"]),
    ...mapState(['baseUrl']),
  },
  watch: {
    show(val) {
    },
    token(val) {
      if (val) this.$emit("closeLogin");
    }
  },
  methods: {}
};
