
export default {
  props: {
    text: {
      type: String,
      default: '分割线'
    }
  },
  data() {
    return {};
  }
};
