export const state = () => ({
    show: false,
    playing: false,
    url: '',
    vr: ''
})

export const actions = {
    play({ commit }, url) {
        commit('updeteUrl', url);
        commit('updeteShow', true);
        commit('updetePlaying', true);
    },
    close({ commit }) {
        commit('updetePlaying', false);
        commit('updeteUrl', '');
        commit('updeteShow', false);
    }
}
  
export const mutations = {
    updetePlaying(state, status) {
        state.playing = status;
    },
    updeteUrl(state, url) {
        state.url = url;
    },
    updeteShow(state, status) {
        state.show = status;
    },
    updateVr(state, data) {
        state.vr = data;
    }
}