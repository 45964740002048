
// 绑定、忘记密码、注册三个模块差别不大，如果没有其他额外需求可以合并
import Login from "./Login";
import Register from "./Register";
import Forget from "./Forget";
import ChangeSuccess from "./ChangeSuccess";
import Bind from "./Bind";
export default {
  props: {
    currentType: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      current: "",
      compMap: {
        login: Login,
        register: Register,
        forget: Forget,
        success: ChangeSuccess,
        bind: Bind
      }
    };
  },
  computed: {
    currentComp() {
      return this.compMap[this.current];
    }
  },
  methods: {
    onStateChange(state) {
      this.current = state;
    },
    // 初始化登录模块状态
    initState() {
      this.current =
        this.$route.query.state ||
        this.currentType ||
        this.$route.query.from ||
        "login";
      console.log('---current---', this.current)
    }
  },
  mounted() {
    this.initState();
  }
};
