
export default {
  data() {
    return {
      type: ''
    };
  },
  computed: {
    des() {
      switch (this.type) {
        case 'bind':
          return '您的手机号绑定成功';
        default:
          return '您的新密码已修改成功';
      }
    }
  },
  methods: {
    initType() {
      this.type = this.$route.query.type;
    },
    onBtnClick() {
      clearTimeout(this.timer);
      this.$emit('changeState', 'login');
    },
    setTimer() {
      this.timer = setTimeout(() => {
        this.$emit('changeState', 'login');
      }, 3000);
    }
  },
  mounted() {
    this.setTimer();
  }
};
