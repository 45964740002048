
import { mapState } from 'vuex'
export default {
  name: 'Footer',
  computed: {
    ...mapState(['navList']),
  },
  data(){
    return{
    }
  },
  mounted() {},
  methods: {
  }
}
