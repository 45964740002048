import { formatTime } from '@/plugins/util'
export const state = () => ({
  baseUrl: '//admin.buwangchuxin.com',
  currentPage: {}, // 当前路由
  winWidth: 0, // 窗口宽度
  winHeight: 0, // 当前窗口高度
  windowResizeState: 0, // 窗口大小变化
  isNavWhite: false, // 导航栏是否白底
  isNav: true, // 是否有头部导航
  isFooter: true, // 是否有底部
  isLogin: false, // 是否显示登录弹窗
  bannerList: [],
  navList: [
    {path: '/', name: 'index', title: '首页'},
    {path: '/map', name: 'map', title: '红色地图'},
    {path: '/material', name: 'material', title: '红色素材'},
    // {path: '/create', name: 'create', title: '创作中心'},
    {path: '/about', name: 'about', title: '联系我们'},
  ]
})

export const mutations = {
  getWindowSize(state) { // 获取窗口大小
    state.winHeight = document.documentElement.clientHeight || window.innerHeight
    state.winWidth = document.documentElement.clientWidth || window.innerWidth
    state.windowResizeState += 1
  },

  setIsNav(state, boolean) {
    state.isNav = boolean
  },

  setNavWhite(state, boolean) {
    state.isNavWhite = boolean;
  },

  setIsFooter(state, boolean) {
    state.isFooter = boolean
  },

  setIsLogin(state, boolean) {
    state.isLogin = boolean
  },

  setCurrentPage(state, router) { // 设置当前页面
    let page = {
      name: router.name,
      path: router.path,
      fullPath: router.fullPath,
      query: router.query,
      params: router.params
    }
    state.currentPage = page
  },

  setBannerList(state, data) {
    state.bannerList = data;
  }

}