
import { mapState, mapActions, mapMutations } from 'vuex';
import { timeFormat } from '~/plugins/util';
import Process from '../Process';
export default {
  components: {
    Process
  },
  data() {
    return {
      time: 2000,
      timePercent: 0,
      volumePercent: 0,
      isOpen: false,
      currentTime: 0,
      duration: 0,
      lock: false
    };
  },
  computed: {
    ...mapState('player', ['show', 'playing', 'url'])
  },
  methods: {
    ...mapActions('player', ['close', 'play']),
    ...mapMutations('player', ['updetePlaying']),
    timeFormat,
    onTimeMove(val) {
      this.lock = true;
      this.timePercent = val;
      this.currentTime = this.duration * val;
    },
    onTimeChange(val) {
      this.timePercent = val;
      this.$refs.audio.currentTime = val * this.duration;
      this.lock = false;
      if (!this.playing) {
        this.updetePlaying(true);
      }
    },
    onVolumeChange(val) {
      this.volumePercent = val;
      this.$refs.audio.volume = val;
    },
    onCloseClick() {
      this.close();
    },
    onPlayBtnClick() {
      this.updetePlaying(!this.playing);
    },
    onTimeUpdate(e) {
      if (this.lock || isNaN(e.target.duration)) return;
      this.currentTime = e.target.currentTime;
      this.duration = e.target.duration;
      this.timePercent = this.currentTime / this.duration;
    },
    onEnd() {
      this.updetePlaying(false);
    },
    onMouseenter() {
      clearTimeout(this.timer);
      this.isOpen = true;
    },
    onMouseleave() {
      this.timer = setTimeout(() => {
        // 音频暂时不隐藏
        // this.isOpen = false;
        this.isOpen = true;
      }, this.time);
    }
  },
  watch: {
    playing(val) {
      const audio = this.$refs.audio;
      if (this.volumePercent === 0) {
        this.onVolumeChange(0.6);
      }
      this.$nextTick(() => {
        val ? audio.play() : audio.pause();
      });
    },
    url(val) {
      if (!val) return;
      const audio = this.$refs.audio;
      this.timePercent = 0;
      this.isOpen = true;
      this.timer = setTimeout(() => {
        this.isOpen = false;
      }, this.time);
      this.$nextTick(() => {
        audio.play();
      });
    }
  }
};
