export const state = () => ({
    title: '',
    vrList: [],
    videoList: [],
    audioList: [],
    bookList: [],
    pictureList: [],
    from: '', // 来源（vr、video、book、pic、audio、question）
    pageInfo: {
        counts: 0,
        page: 1,
        page_size: 8,
        page_total: 0,
    },
    materialList: [
        { path: '/material', name: 'material', title: '热门' },
        { path: '/type/picture', name: 'type/picture', title: '红色图片' },
        { path: '/type/vr', name: 'type/vr', title: '红色VR' },
        { path: '/type/book', name: 'type/book', title: '红色书籍' },
        { path: '/type/audio', name: 'type/audio', title: '红色歌曲' },
        { path: '/type/video', name: 'type/video', title: '红色影视' },
        { path: '/question', name: 'question', title: '红色题库' }
    ],
    pictureNav: [
        { val: 0, title: '首页' },
        { val: 1, title: '人物' },
        { val: 2, title: '景点' },
        { val: 3, title: '事件' },
        { val: 4, title: '风采照' }
    ],
    //版式
    setTypeList: [
        { value: "", label: "全部版式" },
        { value: "1", label: "横版" },
        { value: "2", label: "竖版" }
      ],
      //级别
      levelList: [
        { value: "", label: "全部人物" },
        { value: "1", label: "开国元勋 " },
        { value: "3", label: "将军" },
        { value: "4", label: "红色英烈" },
        { value: "5", label: "红色英雄" },
        { value: "6", label: "红色人物" },
        { value: "7", label: "爱国将领" },
        { value: "8", label: "新时代人物" },
        { value: "9", label: "开国中将" },
        { value: "10", label: "开国大将" },
        { value: "11", label: "开国上将" }
      ],
      sexList: [
        { value: "", label: "不限" },
        { value: "1", label: "男" },
        { value: "2", label: "女" }
      ],

      cityList: [
        { value: "", label: "全部" },
        { value: "赣州", label: "赣州" }
      ],
      countyList: [
        { value: "", label: "全部" },
        { value: "瑞金", label: "瑞金" },
        { value: "兴国", label: "兴国" },
        { value: "于都", label: "于都" }
      ],
      // 评论列表
      commentList: [],
      questionHot: [], // 题库-推荐列表
      detailInfo: {} // 详情信息
})
  
export const mutations = {
    setTitle(state, data) {
        state.title = data;
    },

    setVrList(state, data) {
        state.vrList = data.list || []
        state.pageInfo = data.page_info
    },

    setVideoList(state, data) {
        state.videoList = data.list || []
        state.pageInfo = data.page_info
    },

    setAudioList(state, data) {
        state.audioList = data.list.map(item => {
            let fileType = item.file_path.substring(item.file_path.lastIndexOf('.') + 1);
            if(fileType) fileType = fileType.toUpperCase();
            return {
                ...item,
                fileType
            }
        })
        state.pageInfo = data.page_info
    },

    setBookList(state, data) {
        state.bookList = data.list || []
        state.pageInfo = data.page_info
    },

    setPictureList(state, data) {
        state.pictureList = data.list || [];
        state.pageInfo = data.page_info || {}
    },

    setQuestionHot(state, data) {
        state.questionHot = data.list || [];
    },

    setPageInfo(state, data) {
        state.pageInfo = data;
    },

    setFromText(state, data) {
        state.from = data;
    },

    // data: { from, method, info }
    setDetailInfo(state, result) {
        let obj = {};
        if (result.from === 'vr') {
            obj = {...result.info, ...getVrInfo(result.info) }
            obj.needInfo = { 
                '简介': result.info.attach_intro || result.info.intro || '无'
            }
            state.detailInfo = obj;
            return;
        }
        if(result.from === 'video' || result.from === 'audio') {
            obj = { ...result.info, ...getMediaInfo(result.info) }
            state.detailInfo = obj;
            return;
        }
        if(result.from === 'book') {
            obj = {...result.info, ...getBookInfo(result.info) }
            state.detailInfo = obj;
            return;
        }
        if(result.from === 'question') {
            obj = {...result.info, ...getQuestionInfo(result.info) }
            state.detailInfo = obj;
            return;
        }
        if (result.method === "getFigureImageInfo") {
            let sex = result.info.sex;
            if (sex == 1) sex = "男";
            else if (sex == 2) sex = "女";
            obj.needInfo = {
              性别: sex || "",
              民族: result.info.nation || "",
              "所属区（县）": result.info.area || "",
              籍贯: result.info.birthplace || "",
              入党时间: result.info.partytime || "",
              出生时间: result.info.birthtime || "",
              逝世时间: result.info.deathtime || "",
              人物简介: result.info.description || "",
              人物描述: result.info.content || "",
              历史贡献: result.info.historical || ""
            };
        } else if (result.method == "getNewsImageInfo") {
            obj.needInfo = {
              景点: result.info.title || "",
              地址: result.info.address || "",
              成立时间: result.info.found_time || "",
              景点简介: result.info.description || "",
              景点描述: result.info.content || ""
            };
        } else if (result.method == 'getEventImageInfo') {
            obj.needInfo = {
              类别: eventType(result.info.type),
              发生地点: result.info.occur_place || "",
              发生时间: result.info.occur_time || "",
              故事内容: result.info.content || ""
            };
        } else {
            console.log('result====>',result)
            obj.needInfo = { 
                '简介': result.info.attach_intro || result.info.intro || '无'
            }
            obj.image_list = [
                {
                    id: result.id,
                    attach_name: result.info.attach_name,
                    attach_url: result.info.attach_url,
                }
            ]
        }
        obj = {...result.info, ...obj };
        state.detailInfo = obj;
    },

    setCommentList(state, data) {
        state.commentList = data.list || [];
    }
}

function getVrInfo(info) {
    let obj = {};
    obj.needInfo = {
        '简介': info.content || '无'
    }
    obj.image_list = [
        {
            id: info.id,
            attach_name: info.title,
            attach_url: info.url,
        }
    ]
    return obj;
}

function getMediaInfo(info) {
    let obj = {};
    obj.needInfo = {
        '简介': info.content || '无'
    }
    obj.image_list = [
        {
            id: info.id,
            attach_name: info.title,
            attach_url: info.file_path,
            format: info.format,
            size: info.size
        }
    ]
    return obj;
}

function getBookInfo(info) {
    let obj = {};
    obj.needInfo = {
        '简介': info.intro || info.content || '无'
    }
    if(info.image_list && info.image_list.length) {
        obj.image_list = info.image_list.map(item => {
            return {
                id: info.id,
                attach_name: info.title,
                attach_url: item,
                format: info.format,
                size: info.size
            }
        })
    } else {
        obj.image_list = [
            {
                id: info.id,
                attach_name: info.title,
                attach_url: info.image,
                format: info.format,
                size: info.size
            }
        ]
    }
    
    return obj;
}

function getQuestionInfo(info) {
    let obj = {};
    obj.needInfo = {
        '简介': info.content || '无'
    }
    obj.image_list = [
        {
            id: info.id,
            attach_name: info.title,
            attach_url: info.image
        }
    ]
    return obj;
}

function eventType(type) {
    switch (type) {
      case "0":
        return "战役";
      case "1":
        return "故事";
      case "2":
        return "史实";
      case "3":
        return "研究";
      default:
        return "故事";
    }
}