export const state = () => ({
    mainPlate: [
        { 
          text: '红色人物',
          desc: '忆英雄人物，承红色精神',
          icon: require('../static/img/home_rw_icon.png'),
          act: require('../static/img/home_rw_s_icon.png'),
          path: '/map',
          query: { type: 3}
        },
        {
          text: '红色景点',
          desc: '观红色旧址，忆往昔峥嵘',
          icon: require('../static/img/home_jd_icon.png'),
          act: require('../static/img/home_jd_s_icon.png'),
          path: '/map',
          query: { type: 1}
        },
        { 
          text: '红色故事',
          desc: '民族精神永不朽',
          icon: require('../static/img/home_gs_icon.png'),
          act: require('../static/img/home_gs_s_icon.png'),
          path: '/map',
          query: { type: 2}
        },
        { 
          text: '红色VR',
          desc: '智慧红色文旅，沉浸式体验',
          icon: require('../static/img/home_vr_icon.png'),
          act: require('../static/img/home_vr_s_icon.png'),
          path: '/type/vr'
        },
        { 
          text: '红色图谱',
          desc: '传红色基因，秉初心前行 ',
          icon: require('../static/img/home_tp_icon.png'),
          act: require('../static/img/home_tp_s_icon.png'),
          url: 'http://www.airer.com/yubai/index.html#/RedTuPu'
        },
        { 
          text: '红色图库',
          desc: '红色光芒耀神州',
          icon: require('../static/img/home_tk_icon.png'),
          act: require('../static/img/home_tk_s_icon.png'),
          path: '/type/picture'
        },
        { 
          text: '红色影视',
          desc: '血脉中的革命精神和英雄主义',
          icon: require('../static/img/home_ys_icon.png'),
          act: require('../static/img/home_ys_s_icon.png'),
          path: '/type/video'
        },
        { 
          text: '红色音乐',
          desc: '一首红曲一面旗帜',
          icon: require('../static/img/home_yy_icon.png'),
          act: require('../static/img/home_yy_s_icon.png'),
          path: '/type/audio'
        },
        { 
          text: '红色书籍',
          desc: '读红色经典，坚定理想信念',
          icon: require('../static/img/home_sj_icon.png'),
          act: require('../static/img/home_sj_s_icon.png'),
          path: '/type/book'
        },
        { 
          text: '红色题库',
          desc: '红色文化知识',
          icon: require('../static/img/home_tk_icon_1.png'),
          act: require('../static/img/home_tk_s_icon_1.png'),
          path: '/question'
        }
      ],
      hotList: [], // 热门列表
      historyList: [], // 历史上的今天列表
      today: '',
})
  
export const mutations = {
    setHotList(state, data) {
      console.log('热点推荐--',data)
        state.hotList = data;
    },
    
    setTodayList(state, data) {
        state.historyList = data.figure || [];
        state.today = data.today;
    }
}