
const DES = '获取验证码';
export default {
  props: {
    time: {
      type: Number,
      default: 60
    }
  },
  data() {
    return {
      des: DES,
      isDisable: false,
      timer: null
    };
  },
  methods: {
    onGetClick() {
      if (this.des !== DES) return;
      this.changeDes();
      this.$emit('click');
    },
    ClearTimer() {
      this.des = DES;
      clearInterval(this.timer);
    },
    changeDes() {
      let remainingTime = this.time;
      this.des = remainingTime + '秒';
      this.timer = setInterval(() => {
        if (!remainingTime--) {
          this.ClearTimer();
        } else {
          this.des = remainingTime + '秒';
        }
      }, 1000);
    }
  }
};
