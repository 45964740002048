export const Banner = () => import('../../components/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const Comment = () => import('../../components/Comment.vue' /* webpackChunkName: "components/comment" */).then(c => wrapFunctional(c.default || c))
export const Default = () => import('../../components/Default.vue' /* webpackChunkName: "components/default" */).then(c => wrapFunctional(c.default || c))
export const Dialog = () => import('../../components/Dialog.vue' /* webpackChunkName: "components/dialog" */).then(c => wrapFunctional(c.default || c))
export const Empty = () => import('../../components/Empty.vue' /* webpackChunkName: "components/empty" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const NavBar = () => import('../../components/NavBar.vue' /* webpackChunkName: "components/nav-bar" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const Process = () => import('../../components/Process.vue' /* webpackChunkName: "components/process" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../../components/Search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const Title = () => import('../../components/Title.vue' /* webpackChunkName: "components/title" */).then(c => wrapFunctional(c.default || c))
export const VrMask = () => import('../../components/vrMask.vue' /* webpackChunkName: "components/vr-mask" */).then(c => wrapFunctional(c.default || c))
export const LoginDialog = () => import('../../components/login/Dialog.vue' /* webpackChunkName: "components/login-dialog" */).then(c => wrapFunctional(c.default || c))
export const LoginSeparator = () => import('../../components/login/Separator.vue' /* webpackChunkName: "components/login-separator" */).then(c => wrapFunctional(c.default || c))
export const LoginVerifyCode = () => import('../../components/login/VerifyCode.vue' /* webpackChunkName: "components/login-verify-code" */).then(c => wrapFunctional(c.default || c))
export const LoginWarningWrapper = () => import('../../components/login/WarningWrapper.vue' /* webpackChunkName: "components/login-warning-wrapper" */).then(c => wrapFunctional(c.default || c))
export const MapDetailSide = () => import('../../components/map/DetailSide.vue' /* webpackChunkName: "components/map-detail-side" */).then(c => wrapFunctional(c.default || c))
export const MapPagination = () => import('../../components/map/Pagination.vue' /* webpackChunkName: "components/map-pagination" */).then(c => wrapFunctional(c.default || c))
export const MapPlayer = () => import('../../components/map/Player.vue' /* webpackChunkName: "components/map-player" */).then(c => wrapFunctional(c.default || c))
export const MapRelate = () => import('../../components/map/Relate.vue' /* webpackChunkName: "components/map-relate" */).then(c => wrapFunctional(c.default || c))
export const MapSiderBar = () => import('../../components/map/SiderBar.vue' /* webpackChunkName: "components/map-sider-bar" */).then(c => wrapFunctional(c.default || c))
export const Map = () => import('../../components/map/index.vue' /* webpackChunkName: "components/map" */).then(c => wrapFunctional(c.default || c))
export const MapStyle = () => import('../../components/map/mapStyle.js' /* webpackChunkName: "components/map-style" */).then(c => wrapFunctional(c.default || c))
export const PersonalEditAvatar = () => import('../../components/personal/EditAvatar.vue' /* webpackChunkName: "components/personal-edit-avatar" */).then(c => wrapFunctional(c.default || c))
export const PersonalSideBar = () => import('../../components/personal/SideBar.vue' /* webpackChunkName: "components/personal-side-bar" */).then(c => wrapFunctional(c.default || c))
export const PictureBanner = () => import('../../components/picture/Banner.vue' /* webpackChunkName: "components/picture-banner" */).then(c => wrapFunctional(c.default || c))
export const PictureFormat = () => import('../../components/picture/Format.vue' /* webpackChunkName: "components/picture-format" */).then(c => wrapFunctional(c.default || c))
export const PictureLinks = () => import('../../components/picture/Links.vue' /* webpackChunkName: "components/picture-links" */).then(c => wrapFunctional(c.default || c))
export const PictureSelect = () => import('../../components/picture/Select.vue' /* webpackChunkName: "components/picture-select" */).then(c => wrapFunctional(c.default || c))
export const PictureSort = () => import('../../components/picture/Sort.vue' /* webpackChunkName: "components/picture-sort" */).then(c => wrapFunctional(c.default || c))
export const TypeAudioList = () => import('../../components/type/AudioList.vue' /* webpackChunkName: "components/type-audio-list" */).then(c => wrapFunctional(c.default || c))
export const TypeBookList = () => import('../../components/type/BookList.vue' /* webpackChunkName: "components/type-book-list" */).then(c => wrapFunctional(c.default || c))
export const TypeCollect = () => import('../../components/type/Collect.vue' /* webpackChunkName: "components/type-collect" */).then(c => wrapFunctional(c.default || c))
export const TypeDownload = () => import('../../components/type/Download.vue' /* webpackChunkName: "components/type-download" */).then(c => wrapFunctional(c.default || c))
export const TypeElCarounsel = () => import('../../components/type/ElCarounsel.vue' /* webpackChunkName: "components/type-el-carounsel" */).then(c => wrapFunctional(c.default || c))
export const TypePictureList = () => import('../../components/type/PictureList.vue' /* webpackChunkName: "components/type-picture-list" */).then(c => wrapFunctional(c.default || c))
export const TypePlayer = () => import('../../components/type/Player.vue' /* webpackChunkName: "components/type-player" */).then(c => wrapFunctional(c.default || c))
export const TypeQuestionList = () => import('../../components/type/QuestionList.vue' /* webpackChunkName: "components/type-question-list" */).then(c => wrapFunctional(c.default || c))
export const TypeSemicircle = () => import('../../components/type/Semicircle.vue' /* webpackChunkName: "components/type-semicircle" */).then(c => wrapFunctional(c.default || c))
export const TypeVideoList = () => import('../../components/type/VideoList.vue' /* webpackChunkName: "components/type-video-list" */).then(c => wrapFunctional(c.default || c))
export const TypeVrList = () => import('../../components/type/VrList.vue' /* webpackChunkName: "components/type-vr-list" */).then(c => wrapFunctional(c.default || c))
export const LoginContentModuleBind = () => import('../../components/login/ContentModule/Bind.vue' /* webpackChunkName: "components/login-content-module-bind" */).then(c => wrapFunctional(c.default || c))
export const LoginContentModuleChangeSuccess = () => import('../../components/login/ContentModule/ChangeSuccess.vue' /* webpackChunkName: "components/login-content-module-change-success" */).then(c => wrapFunctional(c.default || c))
export const LoginContentModuleForget = () => import('../../components/login/ContentModule/Forget.vue' /* webpackChunkName: "components/login-content-module-forget" */).then(c => wrapFunctional(c.default || c))
export const Login = () => import('../../components/login/ContentModule/Login.vue' /* webpackChunkName: "components/login" */).then(c => wrapFunctional(c.default || c))
export const LoginContentModuleRegister = () => import('../../components/login/ContentModule/Register.vue' /* webpackChunkName: "components/login-content-module-register" */).then(c => wrapFunctional(c.default || c))
export const LoginContentModule = () => import('../../components/login/ContentModule/index.vue' /* webpackChunkName: "components/login-content-module" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
