let storage = {
    setItem(name, data) {
        if (!process.client) return;
        if(Object.prototype.toString.call(data) == "[object Object]") {
            data.time = new Date().getTime();
            localStorage.setItem(name, JSON.stringify(data));
            return;
        }
        
        localStorage.setItem(name, data);
    },
    getItem(name) {
        if (!process.client) return '';
        let value = localStorage.getItem(name);
        if(!value) return '';
        if(value.indexOf("{") != -1 && value.indexOf("}") != -1) {
            let obj = JSON.parse(localStorage.getItem(name));
            if (!obj || JSON.stringify(obj) == '{}' || !obj.time) return {};
            let valid = obj.time * 24 * 60 * 60 * 1000, now = new Date().getTime();
            if (valid < now) return {}; // 24小时有效期

            return obj;
        }
        return value;
    },
    updateAvatar(url) {
        let obj = this.getItem('userInfo');
        obj.head_path = url;
        this.setItem('userInfo', obj);
    },
    updateName(name) {
        let obj = this.getItem('userInfo');
        obj.nick_name = name;
        this.setItem('userInfo', obj);
    }
}

export default storage;