export const state = () => ({
    obj: {
        name: '',
        type: 3 //当前地图展示类型  1景点 2故事 3人物 4人才地图 5党建地图
                // (接口： 1人物 2事件 3景点)
    },
    markerPic: {
        "1": "//static.buwangchuxin.com/red_open_palm/image/icon/cx-point.png",
        "2": "//static.buwangchuxin.com/red_open_palm/image/icon/cx-story.png",
        "3": "//static.buwangchuxin.com/red_open_palm/image/icon/cx-people.png"
    },
    searchData: [], // 搜索结果列表
    typeMap: {
        1: { text: "人物", color: "linear-gradient(-90deg,#FF8809 0%,#FFAD2C 100%)" },
        2: { text: "景点", color: "linear-gradient(-90deg,#20C0FF 0%,#64D3FF 100%)" },
        3: { text: "故事", color: "linear-gradient(-90deg,#FF5E53 0%,#FF8A82 100%)" }
    }, 
    // 侧栏类别
    sideTypes: [
        {
            name: "人物",
            type: 3,
            icon: "//static.buwangchuxin.com/red_open_palm/image/new/side-person.png",
            num: 0
        },
        {
            name: "景点",
            type: 1,
            icon: "//static.buwangchuxin.com/red_open_palm/image/new/side-scene.png",
            num: 0
        },
        {
            name: "故事",
            type: 2,
            icon: "//static.buwangchuxin.com/red_open_palm/image/new/side-story.png",
            num: 0
        }
    ],
    sideList: [], // 侧栏列表
    pageInfo: {
        counts: 0,
        page: 1,
        page_size: 10,
        page_total: 0
    },
    detailInfo: {},
    relateList: [] // 相关人物|景点|故事
  })
  
  export const mutations = {
    setMapInfoObj(state, data) {
        state.obj = data;
    },
    setSearchData(state, data) {
        state.searchData = data;
    },
    setSideTyes(state, result) {
        state.sideTypes = [
            {
                name: "人物",
                type: 3,
                icon: "//static.buwangchuxin.com/red_open_palm/image/new/side-person.png",
                num: result.figure
            },
            {
                name: "景点",
                type: 1,
                icon: "//static.buwangchuxin.com/red_open_palm/image/new/side-scene.png",
                num: result.news
            },
            {
                name: "故事",
                type: 2,
                icon: "//static.buwangchuxin.com/red_open_palm/image/new/side-story.png",
                num: result.event
            }
        ]
    },
    setSideList(state, data) {
        state.sideList = data.list;
        if(data.page_info) state.pageInfo = data.page_info;
    },
    setPageInfo(state, data) {
        state.pageInfo = data;
    },
    // 景点简介
    setSenceInfo(state, result) {
        result.about_news.forEach(item => {
            item.name = item.title;
        });
        state.relateList = result.about_news;

        state.detailInfo = {
            title: result.news_info.title,
            main: {
              景点简介: result.news_info.description || ""
            },
            image: result.news_info.image || "",
            media: {
              vr: result.news_info.vr_path_path,
              audio: result.news_info.voice_path
            },
            other: {
              地址: result.news_info.address || "",
              成立时间: result.news_info.found_time || ""
            }
        };
    },
    // 故事简介
    setStoryInfo(state, result) {
        result.about_event.forEach(item => {
            item.name = item.title;
        });
        state.relateList = result.about_event;
  
        let type = '';
        switch (result.event_info.type) {
            case "0": type = "战役"; break;
            case "1": type = "故事"; break;
            case "2": type = "史实"; break;
            case "3": type = "研究"; break;
            default: type = "故事"; break;
        }
        state.detailInfo = {
            title: result.event_info.title,
            main: {
              故事内容: result.event_info.content || ""
            },
            image: result.event_info.image || "",
            media: {
              vr: result.event_info.vr_path_path || "",
              audio: result.event_info.voice_path || ""
            },
            other: {
              类别: type,
              来源: result.event_info.source || "",
              发生地点: result.event_info.occur_place || "",
              发生时间: result.event_info.occur_time || ""
            }
        };
    },
    // 人物简介
    setFigureInfo(state, result) {
        state.detailInfo = {
            title: result.figure_info.name,
            main: {
              人物描述: result.figure_info.content,
              历史贡献: result.figure_info.historical
            },
            image: result.figure_info.image,
            other: {
              姓名: result.figure_info.name,
              民族: result.figure_info.nation,
              "所属区(县)": result.figure_info.birthplace,
              性别: result.figure_info.sex,
              籍贯: result.figure_info.birthplace,
              入党时间: result.figure_info.partytime,
              出生时间: result.figure_info.birthtime,
              逝世时间: result.figure_info.deathtime
            }
        };
    },
    setRelate(state, result) {
        result.relate.forEach(item => {
            item.type = item.to_label;
        });
        state.relateList = result.relate;
    }

  }