
export default {
    props: {
        show: { type: Boolean, default: false },
        title: { type: String, default: '' },
        width: { type: String, default: '400px' }
    },
    data() {
        return {
            height: 800
        };
    },
    computed: {
    },
    created() {
    },
    methods: {

    }
};
