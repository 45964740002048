import { Message } from 'element-ui'

function _pad(num, n = 2) {
  let len = num.toString().length;
  while (len < n) {
    num = '0' + num;
    len++;
  }
  return num;
}

export function timeFormat(time) {
  time = time | 0;
  const minute = _pad((time / 60) | 0);
  const second = _pad(time % 60);
  return `${minute}:${second}`;
}

/**
 * 格式化日期
 * @param {Date} date 日期对象
 * @param {String} format 格式字符串
 */
 export function formatTime(date, format = 'YYYY-MM-DD hh:mm:ss') {
  date = new Date(date)
  const o = {
    'M+': date.getMonth() + 1,
    'D+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
    'q+': Math.floor((date.getMonth() + 3) / 3),
    'S': date.getMilliseconds()
  }
  if (/(Y+)/.test(format)) format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
    }
  }
  return format
}

/** 时间差
 * time: 开始时间
 * endtime：结束时间
 * isNeedObj: true返回对象 false返回字符串
 */
export function getTimeDiff(startTime, endTime, isNeedObj = true) {
  var diff = '', obj = {};
  endTime = endTime ? endtime : new Date().getTime();
  var time_diff = endTime - startTime;
  // 计算相差天数  
  var days = Math.floor(time_diff / (24 * 3600 * 1000));
  if (days > 0) {
      diff += days + '天';
      obj.days = days;
  }
  // 计算相差小时数  
  var leave1 = time_diff % ( 24 * 3600 * 1000); 
  var hours = Math.floor(leave1 / (3600 * 1000));
  if (hours > 0) {
      diff += hours + '小时';
      obj.hours = hours;
  }
  // 计算相差分钟数  
  var leave2 =leave1 % (3600 * 1000);
  var minutes = Math.floor(leave2 / (60 * 1000));
  if (minutes > 0) {
      diff += minutes + '分';
      obj.minutes = minutes;
  }
  // 计算相差秒数  
  var leave3 = leave2%(60*1000);
  var seconds = Math.round(leave3/1000);
  if (seconds > 0) {
      diff += seconds + '秒';
      obj.seconds = seconds;
  }
  if(isNeedObj) return obj;
  return diff;
}

// 移动电话
export function checkMobile(mobile) {
  let reg = /^1(3|4|5|6|7|8|9)\d{9}$/;
  if (!reg.test(mobile)) {
      return false;
  }
  return true;
}

// 邮箱
export function checkEmail(email) {
  let reg = new RegExp(
      '^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$'
  );
  if (reg.test(email)) return true;
  else return false;
}

/**
 * base64Data 图片转换成二进制
 * @param {String} base64Data
 */
 export function dataURItoBlob(base64Data) {
  let byteString
  if (base64Data.split(',')[0].indexOf('base64') >= 0) byteString = atob(base64Data.split(',')[1])
  else byteString = unescape(base64Data.split(',')[1])
  let mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0]
  let ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ia], { type: mimeString })
}

// 把base64 转换成文件对象
export function dataURLtoFile(base64Data, fileName) {
  var arr = base64Data.split(','),
  mime = arr[0].match(/:(.*?);/)[1], //base64解析出来的图片类型
  bstr = atob(arr[1]), //对base64串进行操作，去掉url头，并转换为byte   atob为window内置方法
  len = bstr.length,
  ab = new ArrayBuffer(len), //将ASCII码小于0的转换为大于0
  u8arr = new Uint8Array(ab); //
  while (len--) {
      u8arr[len] = bstr.charCodeAt(len)
  };
  // 创建新的 File 对象实例[utf-8内容，文件名称或者路径，[可选参数，type：文件中的内容mime类型]]
  return new File([u8arr], fileName, {
      type: mime
   })
};

/**
* 随机字符串
*/
export function randomString(len = 32) {
  var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  var maxPos = chars.length
  var pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}

/** 跳转链接
 * router：this.$router
 * link：{ ptah: xx, query: {} }
 * isNew: 是否新窗口打开
 */
export function openUrl(router, link, isNew = false) {
  if(!isNew) router.push(link)
  else {
    const url = router.resolve(link);
    window.open(url.href, "_blank");
  }
}

export function ErrorMessage(msg) {
  Message({
    type: 'error',
    offset: 80,
    message: msg
  })
}