import Vue from 'vue'
import { 
    Image,
    Icon, 
    Input,
    Button,
    Form,
    FormItem,
    Message,
    Carousel,
    CarouselItem,
    Pagination,
    Scrollbar,
    Dialog
} from 'element-ui'

Vue.use(Image)
Vue.use(Icon)
Vue.use(Input)
Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Pagination)
Vue.use(Scrollbar)
Vue.use(Dialog)

Vue.prototype.$message = Message