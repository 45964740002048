import { render, staticRenderFns } from "./NavBar.vue?vue&type=template&id=149d660e&scoped=true&"
import script from "./NavBar.vue?vue&type=script&lang=js&"
export * from "./NavBar.vue?vue&type=script&lang=js&"
import style0 from "./NavBar.vue?vue&type=style&index=0&id=149d660e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "149d660e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Dialog: require('/www/wwwroot/red_open_palm_3.0/components/Dialog.vue').default})
