import { render, staticRenderFns } from "./default.vue?vue&type=template&id=d9750c2c&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=d9750c2c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavBar: require('/www/wwwroot/red_open_palm_3.0/components/NavBar.vue').default,Footer: require('/www/wwwroot/red_open_palm_3.0/components/Footer.vue').default,VrMask: require('/www/wwwroot/red_open_palm_3.0/components/vrMask.vue').default,LoginDialog: require('/www/wwwroot/red_open_palm_3.0/components/login/Dialog.vue').default})
