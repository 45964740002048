
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      height: 800
    };
  },
  computed: {
    ...mapState("player", ["vr"])
  },
  created() {
    this.getHeight();
    if (process.client) {
      window.onresize = () => {
        this.getHeight();
      };
    }
    
  },
  methods: {
    ...mapMutations("player", ["updateVr"]),
    handleClose() {
      this.updateVr("");
    },
    getHeight() {
      if (process.client) {
        const windowHeight = document.body.clientHeight;
        const height = windowHeight - windowHeight / 10 - 90;
        this.height = height;
      }
    }
  }
};
