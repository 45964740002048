export const state = () => ({
    typeList: [
        { 
            text: '全部题库',
            color: '#C48EFF',
            icon: require('../static/img/que/type-1.png'),
        },
        { 
            text: '红色人物题库',
            color: '#F7B942',
            icon: require('../static/img/que/type-2.png'),
            query: { type: 1 }
        },
        { 
            text: '红色景点题库 ',
            color: '#8EA3FF',
            icon: require('../static/img/que/type-3.png'),
            query: { type: 2 }
        },
        {  
            text: '红色事件题库',
            color: '#15D79F',
            icon: require('../static/img/que/type-4.png'),
            query: { type: 3 }
        }
    ],
    // 题库列表
    personList: [],
    scenceList: [],
    storyList: [],
    pageInfo: {
        counts: 0,
        page: 1,
        page_size: 8,
        page_total: 0,
    },
    exam_info: {}, // 试卷信息
    questionList: [] // 答题列表
})
  
export const mutations = {
    setPersonList(state, data) {
        state.personList = data.list;
        state.pageInfo = data.page_info;
    },

    setScenceList(state, data) {
        state.scenceList = data.list;
        state.pageInfo = data.page_info;
    },

    setStoryList(state, data) {
        state.storyList = data.list;
        state.pageInfo = data.page_info;
    },

    setAnswerList(state, data) {
        state.questionList = data.question_list || [];
        state.exam_info = data.exam_info || {};
    }
}