var baseUrl ='';

// console.log('是否客户端----',process.client)
// console.log('环境===', process.env.NODE_ENV)
var prop = 'https:'
if (process.client) {
  prop = window.location.protocol
}
// if(process.env.NODE_ENV == 'development') baseUrl = ''
// else baseUrl = prop + '//admin.buwangchuxin.com'

baseUrl = prop + '//www.buwangchuxin.com'

const api = {
  uploadImage: '/api/Upload/image', // 上传图片
  getBanner: '/api/webmap/getBanner', // banner
  addComment: '/api/webmap/addComment', // 评论 地图
  addPageComment: '/api/webmap/addPageComment', // 页面评论 红色素材

  /** 地图 */
  getPoint: '/api/webmap/getPoint', // 地图标记点
  hotSearch: '/api/webmap/hotSearch', // 热门搜索
  search: '/api/webmap/search', // 搜索
  total: '/api/webmap/total', // 侧导航类别总数
  hotNews: '/api/webmap/hotNews', // 热门景点
  hotEvent: '/api/webmap/hotEvent', // 热门故事
  hotFigure: '/api/webmap/hotFigure', // 热门人物
  getPlaceList: '/api/Rencai/getPlaceList', // 人才地图

  getNewsIntr: '/api/webmap/getNewsIntr', // 景点简介
  getEvent: '/api/webmap/getEvent', // 故事简介
  getFigure: '/api/webmap/getFigure', // 人物简介

  getAboutFigure: '/api/webmap/getAboutFigure', // 相关人物
  getHistoryToday: '/api/webmap/getHistoryToday', // 历史上的今天

  /** 登录 */
  webLogin: '/api/Oauth/webLogin', // 登录
  getAccountInfo: '/api/Webmap/getAccountInfo', // 获取账户信息
  sendSms: '/api/Oauth/sendSms', // 获取验证码
  webRegister: '/api/Oauth/webRegister', // 获取验证码
  getBackPassword: '/api/Webmap/getBackPassword', // 修改密码
  bindMobile: '/api/Thirdlogin/bindMobile', // 绑定手机号


  
  /************ 各类型 红色素材 ***********/

  getPageCommentList: '/api/Webmap/getPageCommentList', // 获取页面评论

  /** 红色图库 */
  getRecomImageForYou: '/api/Webmap/getRecomImageForYou', // 为你推荐
  getFigureImageList: '/api/Webmap/getFigureImageList', // 人物列表接口
  getNewsImageList: '/api/Webmap/getNewsImageList', // 景点列表接口
  getEventImageList: '/api/Webmap/getEventImageList', // 事件列表接口
  getLifeImageList: '/api/Webmap/getLifeImageList', // 风采照列表
  getFigureImageInfo: '/api/Webmap/getFigureImageInfo', // 人物图详情
  getNewsImageInfo: '/api/Webmap/getNewsImageInfo', // 景点图详情
  getEventImageInfo: '/api/Webmap/getEventImageInfo', // 事件图详情
  getImageInfo: '/api/Tool/getImageInfo', // 通过图片名称获取图片信息(风采照详情)
  downloadImage: '/api/Webmap/downloadImage', // 图片下载

  /** 红色VR */
  getVrList: '/api/Webmap/getVrList', // 红色VR列表
  getVrInfo: '/api/Webmap/getVrInfo', // 红色VR详情

  /** 红色影视 */
  getVideoList: '/api/Webmap/getVideoList', // 红色视频列表
  getVideoInfo: '/api/Webmap/getVideoInfo', // 红色视频详情

  /** 红色音频 */
  getMusicList: '/api/Webmap/getMusicList', // 红色音频列表
  getMusicInfo: '/api/Webmap/getMusicInfo', // 红色音频详情

  /** 红色书籍 */
  getBookList: '/api/Webmap/getBookList', // 红色书籍列表
  getBookInfo: '/api/Webmap/getBookInfo', // 红色书籍详情

  /** 题库 */
  getTikuList: '/api/Webmap/getTikuList', // 红色题库列表
  getTikuInfo: '/api/Webmap/getTikuInfo', // 红色题库详情
  getExamInfo: '/api/Webmap/getExamInfo', // 随机问答-获取试卷题信息
  examAnswer: '/api/Webmap/examAnswer', // 随机问答-考试答题
  getExamResult: '/api/Webmap/getExamResult', // 随机问答-获取试卷结果
  handExam: '/api/Webmap/handExam', // 随机问答-考试交卷

  /** 个人中心 */
  updateUserInfo: '/api/Webmap/updateUserInfo', // 更新用户信息
  getAccountInfo: '/api/Webmap/getAccountInfo', // 获取账户信息
  updateAccountInfo: '/api/Webmap/updateAccountInfo', // 更改账户信息
  resetPassword: '/api/Webmap/resetPassword', // 修改密码

  /** 支付、订单 */
  makeDownloadOrder: '/api/Order/makeDownloadOrder', // 创建订单
  getPayInfo: '/api/Order/getPayInfo', // 获取支付二维码
  getOrderStatus: '/api/Order/getOrderStatus', // 检测订单支付状态
  getDownAttachList: '/api/Webmap/getDownAttachList', // 下载记录
  getOrderAttachList: '/api/Webmap/getOrderAttachList', // 购买记录

  addContact: '/api/Webmap/addContact' // 联系我们
}

for (let key in api) {
  api[key] = baseUrl + api[key]
}

export default api

